import React from "react";
import { Link } from "gatsby";
import "../styles/offer.scss";
import arrow1 from "../images/arrow1.svg";
import arrow2 from "../images/arrow2.svg";

const OfferPreview = ({ title, image, slug, benefits }) => (
  <div className="offer">
    <Link className="offer-link" to={`/kariera/${slug}`}>
      <img src={image} alt="oferta" />
      <div className="desc">
        <h3>{title}</h3>
        <div className="benefits">
          {benefits.map((item) => (
            <p>{item}</p>
          ))}
        </div>
      </div>
      <div className="arrows">
        <img className="arrow1" src={arrow1} alt="" />
        <img className="arrow2" src={arrow2} alt="" />
      </div>
    </Link>
  </div>
);

export default OfferPreview;
