import React from "react";
import { graphql } from "gatsby";
import "../styles/career.scss";
import Heading from "../components/Heading";
import slugify from "slugify";
import OfferPreview from "../components/OfferPreview";
import Seo from "../components/seo";

const Career = ({ data }) => {
  const {
    allDatoCmsOffer: { nodes },
  } = data;
  return (
    <>
      <Seo
        title="Oferty pracy"
        description={`Aktualne oferty pracy - Usługi transportowe Zbigniew Lidźbiński`}
        keywords={[
          "oferty pracy konin",
          "usługi transportowe",
          "praca kierowca konin",
          "c+e",
        ]}
      />
      <section className="carrer">
        <div className="container">
          <Heading Tag="h2" text="Oferty pracy" />
          {nodes.map(({ title, image, benefit1, benefit2 }) => (
            <OfferPreview
              key={title}
              title={title}
              image={image.url}
              benefits={[benefit1, benefit2]}
              slug={slugify(title, { lower: true })}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  {
    allDatoCmsOffer {
      nodes {
        title
        image {
          url
        }
        benefit1
        benefit2
      }
    }
  }
`;

export default Career;
